(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var OFF = require('./constants.js').OFF;
var xhr = require('./xhr.js');
var widgetIFrameProtocol = 'https:' === document.location.protocol ? 'https:' : 'http:';
var TRUSTBOX_CNAME = 'TrustboxSplitTest';
// When using loadFromElement in single-page apps, multiple TrustBoxes can be asking for settings in parallel
var settingsCallbackQueue = [];

// http://stackoverflow.com/a/11319865
// needs to support something.co.uk, something.com.au and something.dk
function getTopLevelDomain() {
  var weirdCookie = 'weird_get_top_level_domain=cookie';
  var hostname = document.location.hostname.split('.');
  for (var i = hostname.length - 1; i >= 0; i--) {
    var h = hostname.slice(i).join('.');
    document.cookie = weirdCookie + ';domain=.' + h + ';';
    if (document.cookie.indexOf(weirdCookie) > -1) {
      document.cookie = weirdCookie.split('=')[0] + '=;domain=.' + h + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      return h;
    }
  }
}

function getCookie() {
  var name = TRUSTBOX_CNAME + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
}

// Returns the 'expires=...' string to pass along to the widget iframe for creation of cookies on widget.tp.com
function setCookie(cvalue, expiryDate) {
  var topDomain = getTopLevelDomain();
  var path = 'path=/';
  var domain = 'domain=' + topDomain;
  var expires = 'expires=' + expiryDate;
  document.cookie = [TRUSTBOX_CNAME + '=' + cvalue, path, domain, expires].join('; ');
  return expires;
}

function getSettingsFromCookie(businessUnitId) {
  var cookie = void 0;
  try {
    cookie = getCookie();
    var storedData = JSON.parse(decodeURIComponent(cookie));
    if (storedData.businessUnitId === businessUnitId) {
      return storedData;
    }
  } catch (e) {
    // Botched cookie storage, remove
    if (cookie) {
      var domain = 'domain=' + window.location.hostname.replace(/^.*\.([^.]+\.[^.]+)/, '$1');
      document.cookie = TRUSTBOX_CNAME + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;' + domain;
    }
  }
}

function saveSettings(settings) {
  if (settings) {
    if (!settings.expiry) {
      var days = 100;
      var millisecsInADay = 86400000;
      settings.expiry = new Date(new Date().getTime() + days * millisecsInADay).toUTCString();
    }
    return setCookie(encodeURIComponent(JSON.stringify(settings)), settings.expiry);
  }
}

/* https://gist.github.com/LeverOne/1308368 */
function generateGuid(a, b) {
  /* eslint-disable no-empty */
  for (b = a = ''; a++ < 36; b += a * 51 & 52 ? (a ^ 15 ? 8 ^ Math.random() * (a ^ 20 ? 16 : 4) : 4).toString(16) : '-') {}
  return b;
  /* esline-enable no-empty */
}

var generateSettingsFromApiData = function generateSettingsFromApiData(apiData, businessUnitId) {
  if (!apiData || !apiData.groups) {
    return null;
  }

  var totalDistribution = 0;
  var numberOfGroups = 0;
  var validGroups = [];
  for (var i = 0; i < apiData.groups.length; i++) {
    if (typeof apiData.groups[i].ratio === 'number') {
      totalDistribution += apiData.groups[i].ratio;
      ++numberOfGroups;
      validGroups.push(apiData.groups[i]);
    }
  }
  if (numberOfGroups === 0) {
    return null;
  } else if (numberOfGroups === 1 && totalDistribution < 1) {
    // Add a fake invisible group to add up to 100% distribution
    validGroups.push({ name: OFF, ratio: 1 - totalDistribution });
    totalDistribution = 1;
  }
  var startBracket = 0;
  var toss = Math.random() * totalDistribution; // In case we go over 1
  var group = validGroups[0].name;
  var allGroups = [];
  for (var _i = 0; _i < validGroups.length; _i++) {
    if (toss >= startBracket && toss < startBracket + validGroups[_i].ratio) {
      group = validGroups[_i].name;
    }
    allGroups.push(validGroups[_i].name);
    startBracket += validGroups[_i].ratio;
  }
  // We always generate a new anonymous ID, but it will only be used if the tp-consumer-id cookie is empty
  return {
    allGroups: allGroups,
    group: group,
    testId: apiData.testId,
    businessUnitId: businessUnitId,
    session: generateGuid(),
    sessionStart: +new Date(),
    anonymousId: generateGuid()
  };
};

// Sends the same settings to every TrustBox that was waiting for them
function processCallbackQueue(settings) {
  var expires = saveSettings(settings);
  while (settingsCallbackQueue.length) {
    var nextCb = settingsCallbackQueue.pop();
    nextCb(settings, expires);
  }
}

function getABSettingsAsync(businessUnitId, callback) {
  var settingsFromCookie = getSettingsFromCookie(businessUnitId);
  var sessionDuration = 1800000; // 30 mins in millisecs
  if (settingsFromCookie && (!settingsFromCookie.sessionStart || +new Date() < settingsFromCookie.sessionStart + sessionDuration)) {
    var expires = 'expires=' + settingsFromCookie.expiry;
    callback(settingsFromCookie, expires);
    return;
  }

  settingsCallbackQueue.push(callback);

  if (settingsCallbackQueue.length > 1) {
    // A settings request is already in flight
  } else {
    xhr.xhrGet({
      url: widgetIFrameProtocol + '#{WidgetRootNoProtocol}/v1/ratios/' + businessUnitId,
      success: function success(data) {
        var settingsFromApi = generateSettingsFromApiData(data, businessUnitId);
        if (settingsFromCookie && settingsFromCookie.testId === settingsFromApi.testId) {
          // New session with same settings
          settingsFromCookie.session = generateGuid();
          settingsFromCookie.sessionStart = +new Date();
          return processCallbackQueue(settingsFromCookie);
        } else {
          return processCallbackQueue(settingsFromApi);
        }
      },
      error: function error() {
        callback(null);
      }
    });
  }
}

module.exports = { getABSettingsAsync: getABSettingsAsync };

},{"./constants.js":2,"./xhr.js":8}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var EMPTY_WIDGET_ID = '000000000000000000000000';
var OFF = 'OFF';

exports.EMPTY_WIDGET_ID = EMPTY_WIDGET_ID;
exports.OFF = OFF;

},{}],3:[function(require,module,exports){
'use strict';

var hasCreatedJsonLd = false;
function createJsonLdElement(jsonLdData) {
  /* eslint-disable no-empty */
  try {
    if (hasCreatedJsonLd) {
      return;
    }

    var jsonElement = document.createElement('script');
    jsonElement.setAttribute('type', 'application/ld+json');
    jsonElement.innerHTML = JSON.stringify(jsonLdData);
    document.head.appendChild(document.createComment('Added by Trustpilot'));
    document.head.appendChild(jsonElement);
    document.head.appendChild(document.createComment('/Added by Trustpilot'));
    hasCreatedJsonLd = true;
  } catch (ignoreIE8) {}
  /* eslint-enable no-empty */
}

var xhr = require('./xhr.js');
var protocol = 'https:' === document.location.protocol ? 'https:' : 'http:';

function getOrganizationOrPlaceSnippet(_ref) {
  var schemaType = _ref.schemaType,
      businessunitId = _ref.businessunitId,
      location = _ref.location,
      locale = _ref.locale,
      templateId = _ref.templateId;

  var params = ['type=' + schemaType, 'url=' + encodeURIComponent(window.location.href), 'templateId=' + templateId, 'locale=' + locale].join('&');
  var organizationPath = '/data/jsonld/business-unit/' + businessunitId;
  var locationPath = '/data/jsonld/business-unit/' + businessunitId + '/location/' + location;
  var path = location ? locationPath : organizationPath;
  var url = protocol + '#{WidgetRootNoProtocol}' + path + '?' + params;

  xhr.xhrGet({
    url: url,
    success: function success(data) {
      data.url = document.location.href;
      createJsonLdElement(data, null);
    },
    error: function error(data) {
      // eslint-disable-next-line
      console.error(data);
    }
  });
}

function getProductSnippet(_ref2) {
  var businessunitId = _ref2.businessunitId,
      locale = _ref2.locale,
      templateId = _ref2.templateId,
      name = _ref2.name,
      sku = _ref2.sku,
      reviewnumber = _ref2.reviewnumber,
      price = _ref2.price,
      priceCurrency = _ref2.priceCurrency,
      availability = _ref2.availability;

  var language = locale && locale.split('-')[0];

  var includeImported = templateId === '5763bccae0a06d08e809ecbb'; // multisource seo
  var regularPath = '/data/jsonld/business-unit/' + businessunitId + '/product';
  var importedPath = '/data/jsonld/business-unit/' + businessunitId + '/product-imported';
  var path = includeImported ? importedPath : regularPath;

  var url = protocol + '#{WidgetRootNoProtocol}' + path + '?sku=' + encodeURIComponent(sku) + '&numberOfReviews=' + (reviewnumber || 10) + '&productName=' + name + '&language=' + language + '&templateId=' + templateId + '&url=' + encodeURIComponent(window.location.origin + window.location.pathname);

  var offer = price && priceCurrency && availability ? {
    '@type': 'Offer',
    priceCurrency: priceCurrency,
    price: price,
    availability: availability
  } : null;

  xhr.xhrGet({
    url: url,
    success: function success(data) {
      if (offer) {
        data.offers = offer;
      }
      // Structured Data rules: Either ‘offers’, ‘review’ or ‘aggregateRating’ should be specified
      if (data.offers || data.review || data.aggregateRating) {
        createJsonLdElement(data, offer);
      }
    },
    error: function error(data) {
      // eslint-disable-next-line
      console.error(data);
    }
  });
}

var snippet = function snippet(dataset) {
  if (hasCreatedJsonLd) {
    // in case there's a second widget with snippet markup
    return false;
  }

  if (dataset.schemaType) {
    // organisation or place JSON-LD
    getOrganizationOrPlaceSnippet(dataset);
    return true;
  } else if (dataset.name && dataset.sku) {
    // product JSON-LD
    getProductSnippet(dataset);
    return true;
  }
  return false;
};

module.exports = snippet;

},{"./xhr.js":8}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _constants = require('./constants');

var _snippets = require('./snippets');

var _snippets2 = _interopRequireDefault(_snippets);

var _abSettings = require('./ab-settings');

var _widget = require('./widget');

var _widget2 = _interopRequireDefault(_widget);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var WidgetManagement = function () {
  function WidgetManagement(window, document, version) {
    _classCallCheck(this, WidgetManagement);

    this.window = window;
    this.document = document;
    this.widgets = [];
    this.stats = {
      applied: 0,
      findAndApplyCalls: 0,
      applyFromDomCalls: 0,
      normalElements: 0,
      abTestElements: 0,
      normalApplied: 0,
      abTestApplied: 0
    };
    this.version = version;
  }

  /**
   * Initialize the widgets on the page.
   *
   * Ensures that AB settings are fetched if required, prior to constructing the
   * widgets on the page.
   */


  _createClass(WidgetManagement, [{
    key: 'initialize',
    value: function initialize() {
      var _this = this;

      if (this.hasABTestElements()) {
        (0, _abSettings.getABSettingsAsync)(this.businessUnitId, function (ABSettings, sessionExpiry) {
          _this.abSettings = ABSettings;
          _this.sessionExpiry = sessionExpiry;
          _this.findAndApplyWidgets();
        });
      } else {
        this.findAndApplyWidgets();
      }
    }

    /**
     * Check the state of loading, and then initialize the widgets on the page.
     */

  }, {
    key: 'initializeOnPageLoad',
    value: function initializeOnPageLoad() {
      if (this.document.readyState !== 'loading') {
        this.initialize();
      } else {
        this.document.addEventListener('DOMContentLoaded', this.initialize.bind(this));
      }
    }

    /**
     * Create a widget at a given DOM element.
     *
     * This method will construct a widget on a DOM element. If it has already
     * been loaded, it will only be created if the forceReload flag is true.
     *
     * @param {HTMLElement} element - The DOM element on which to create a widget
     * @param {Boolean} forceReload - Flag whether to force a reload of an
     * already existing widget
     */

  }, {
    key: 'applyWidgetFromDomElement',
    value: function applyWidgetFromDomElement(element, forceReload) {
      var _this2 = this;

      var alreadyLoaded = element.firstChild && element.firstChild.tagName === 'IFRAME';

      if (alreadyLoaded && !forceReload) {
        return;
      }

      if ('group' in element.dataset) {
        (0, _abSettings.getABSettingsAsync)(element.dataset.businessunitId, function (ABSettings, sessionExpiry) {
          _this2.abSettings = ABSettings;
          _this2.sessionExpiry = sessionExpiry;
          _this2.createWidget(element);
        });
      } else {
        this.createWidget(element);
      }
    }

    /**
     * Find all widget container elements and initalize widgets in each.
     *
     * This method is called by some of our clients when using SPAs. We should
     * treat this as a documented public method and ensure it works consistently
     * and properly.
     */

  }, {
    key: 'findAndApplyWidgets',
    value: function findAndApplyWidgets() {
      var _this3 = this;

      this.stats.findAndApplyCalls += 1;
      var elements = this.widgetElements;

      if (!elements || elements.length === 0) {
        return;
      }
      this.stats.normalElements = elements.filter(function (element) {
        return !('group' in element.dataset);
      }).length;
      this.stats.abTestElements = elements.filter(function (element) {
        return 'group' in element.dataset;
      }).length;
      elements.forEach(function (element) {
        return _this3.createWidget(element);
      });
    }

    /**
     * Check whether any elements are AB test elements.
     */

  }, {
    key: 'hasABTestElements',
    value: function hasABTestElements() {
      return [].slice.call(this.widgetElements).some(function (element) {
        return 'group' in element.dataset;
      });
    }

    /**
     * Create a widget at the given element.
     *
     * Takes a DOM element and constructs a widget at that element. If the element
     * dataset indicates the widget is included in AB testing, this method only
     * constructs the widget if it has a group matching that of the current user.
     */

  }, {
    key: 'createWidget',
    value: function createWidget(element) {
      this.removeWidget(element);
      if (this.checkWidgetDisplayAndUpdateDataset(element)) {
        var dataset = element.dataset;
        (0, _snippets2.default)(dataset);

        var isABWidget = 'group' in dataset && this.abSettings;
        var widgetArgs = isABWidget ? {
          container: element,
          dataset: dataset,
          session: this.abSettings.session,
          anonymousId: this.abSettings.anonymousId,
          testId: this.abSettings.testId,
          sessionExpiry: this.sessionExpiry
        } : { container: element, dataset: dataset };
        var widget = new _widget2.default(widgetArgs);
        widget.initialize();

        this.stats.applied += 1;
        this.stats[isABWidget ? 'abTestApplied' : 'normalApplied'] += 1;
        this.stats.applyFromDomCalls += 1;
        this.widgets.push(widget);

        return widget;
      }
      return null;
    }

    /**
     * Check whether a widget should be displayed, and maybe update dataset.
     *
     * The dataset for a given element will be inspected and checked for validity.
     * If it is a valid normal widget, it is an AB widget in a matching group, or
     * it is an empty widget, this will return true. Otherwise, the method will
     * return false.
     *
     * If this is an AB widget, and the group in settings is off, the dataset is
     * updated to reflect this.
     */

  }, {
    key: 'checkWidgetDisplayAndUpdateDataset',
    value: function checkWidgetDisplayAndUpdateDataset(element) {
      var dataset = element.dataset;
      if (!this.abSettings || !('group' in dataset)) {
        return true;
      } else if (this.abSettings.group === dataset.group) {
        return true;
      } else if (this.abSettings.group === _constants.OFF) {
        dataset.templateId = _constants.EMPTY_WIDGET_ID;
        dataset.styleHeight = '0';
        dataset.group = _constants.OFF;
        return true;
      }
      return false;
    }

    /**
     * Remove a widget element from the DOM.
     */

  }, {
    key: 'removeWidget',
    value: function removeWidget(element) {
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    }

    /**
     * Close all open popups.
     */

  }, {
    key: 'closePopups',
    value: function closePopups() {
      this.widgets.forEach(function (widget) {
        return widget.closePopup();
      });
    }
  }, {
    key: 'businessUnitId',
    get: function get() {
      return this.widgetElements[0].dataset.businessunitId;
    }
  }, {
    key: 'widgetElements',
    get: function get() {
      return [].slice.call(this.document.getElementsByClassName('trustpilot-widget'));
    }
  }]);

  return WidgetManagement;
}();

exports.default = WidgetManagement;

},{"./ab-settings":1,"./constants":2,"./snippets":3,"./widget":6}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var widgetHtmlFile = 'index.html';
var widgetIFrameProtocol = 'https:' === document.location.protocol ? 'https:' : 'http:';
var widgetIFrameOrigin = widgetIFrameProtocol + '#{WidgetRootNoProtocol}';
var widgetBaseUrl = widgetIFrameProtocol + '#{WidgetRootNoProtocol}/trustboxes';

function makeIframeElement(options) {
  var iframe = document.createElement('iframe');
  iframe.style.position = options.position;
  iframe.style.zIndex = options.zindex;
  iframe.style.margin = options.margin;
  iframe.style.top = options.top;
  iframe.style.bottom = options.bottom;
  iframe.style.left = options.left;
  iframe.style.right = options.right;
  iframe.style.height = options.height;
  iframe.style.width = options.width;
  iframe.style.borderStyle = options.borderStyle;
  iframe.style.backgroundColor = options.backgroundColor;
  iframe.style.display = options.display;
  iframe.style.overflow = options.overflow;
  iframe.frameBorder = options.frameBorder;
  iframe.scrolling = options.scrolling;
  iframe.allowTransparency = options.allowTransparency;
  iframe.title = 'Customer reviews powered by Trustpilot';
  iframe.src = options.src;

  return iframe;
}

function getAttributeValue(attribute) {
  var suffixRegEx = /(px|\%)/i;
  var value = '';

  if (attribute && attribute.length > 0) {
    attribute = attribute.toLowerCase();
    if (suffixRegEx.test(attribute)) {
      value = attribute;
    } else {
      value = attribute + 'px';
    }
  }
  return value;
}

var IFrame = function () {
  function IFrame(name, dataset, iframeData) {
    _classCallCheck(this, IFrame);

    this.name = name;
    this.dataset = dataset;
    this.iframeData = iframeData || this.defaultIframeOptions;
  }

  _createClass(IFrame, [{
    key: 'getIframeOptionsFromData',
    value: function getIframeOptionsFromData() {
      var iframeData = this.iframeData || { styles: {} };
      return _extends({}, this.defaultIframeOptions, {
        position: iframeData.styles.position || '',
        zindex: iframeData.styles.zindex || '',
        margin: iframeData.styles.margin || '',
        top: iframeData.styles.top || '',
        bottom: iframeData.styles.bottom || '',
        left: iframeData.styles.left || '',
        right: iframeData.styles.right || '',
        height: iframeData.styles.height || '',
        width: iframeData.styles.width || this.defaultIframeOptions.styles.width || '',
        display: iframeData.show ? 'block' : 'none',
        src: this.baseUrl + iframeData.source + this.queryString,
        borderStyle: this.defaultIframeOptions.styles.borderStyle,
        overflow: this.defaultIframeOptions.styles.overflow
      });
    }
  }, {
    key: 'initialize',
    value: function initialize(container, onLoad) {
      this._iframe = makeIframeElement(this.getIframeOptionsFromData());

      container.appendChild(this._iframe);
      this._iframe.addEventListener('load', onLoad);
    }
  }, {
    key: 'sendMessage',
    value: function sendMessage(message) {
      if (this._iframe.contentWindow) {
        message = JSON.stringify(message); // This is to make it IE8 compatible
        this._iframe.contentWindow.postMessage(message, widgetIFrameOrigin);
      }
    }
  }, {
    key: 'isInViewport',
    value: function isInViewport(allowance) {
      var rect = this._iframe.getBoundingClientRect();

      var rectSize = {
        width: rect.width || this._iframe.offsetWidth,
        height: rect.height || this._iframe.offsetHeight
      };

      return rect.top >= 0 && rect.left >= 0 && rect.bottom - rectSize.height * allowance <= (window.innerHeight || document.documentElement.clientHeight) && rect.right - rectSize.width * allowance <= (window.innerWidth || document.documentElement.clientWidth);
    }
  }, {
    key: 'setWidgetId',
    value: function setWidgetId(widgetId) {
      this.sendMessage({ command: 'setId', widgetId: widgetId });
    }
  }, {
    key: 'setStyle',
    value: function setStyle(styles) {
      var _this = this;

      Object.keys(styles).forEach(function (property) {
        var style = styles[property];
        _this._iframe.style[property] = style;
      });
    }
  }, {
    key: 'hide',
    value: function hide() {
      this.setStyle({ display: 'none' });
    }
  }, {
    key: 'resizeHeight',
    value: function resizeHeight(height) {
      if (typeof height !== 'number' || height === 0) {
        return;
      }
      this._iframe.style.height = height + 'px';
    }
  }, {
    key: 'dimensions',
    get: function get() {
      return {
        height: getAttributeValue(this.dataset.styleHeight),
        width: getAttributeValue(this.dataset.styleWidth)
      };
    }

    /**
     * Get the query string for populating this iframe's URL.
     *
     * The query string also includes hash parameters. These are used to ensure
     * that the URL does not contain unneeded query params for fetching the
     * Trustbox HTML itself as we include a number of parameters only needed for
     * making the TrustBox data call.
     */

  }, {
    key: 'queryString',
    get: function get() {
      var _this2 = this;

      var mkPair = function mkPair(attribute) {
        return attribute + '=' + encodeURIComponent(_this2.dataset[attribute]);
      };
      var mkPairs = function mkPairs(p) {
        return Object.keys(_this2.dataset).filter(p).map(mkPair);
      };

      var searchKeys = ['businessunitId', 'templateId'];
      var searchPairs = mkPairs(function (k) {
        return searchKeys.indexOf(k) !== -1;
      });
      var hashPairs = mkPairs(function (k) {
        return searchKeys.indexOf(k) === -1;
      });
      return '?' + searchPairs.join('&') + '#' + hashPairs.join('&');
    }
  }, {
    key: 'templateId',
    get: function get() {
      return this.dataset.templateId;
    }
  }, {
    key: 'baseUrl',
    get: function get() {
      return widgetBaseUrl + '/' + this.templateId + '/';
    }
  }, {
    key: 'defaultIframeOptions',
    get: function get() {
      return {
        source: widgetHtmlFile,
        frameBorder: 0,
        allowTransparency: 'true',
        scrolling: 'no',
        styles: {
          borderStyle: 'none',
          backgroundColor: 'transparent',
          display: 'block',
          overflow: 'hidden',
          height: this.dimensions.height,
          width: this.dimensions.width,
          position: 'relative'
        },
        show: true
      };
    }
  }]);

  return IFrame;
}();

exports.default = IFrame;

},{}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _tracking = require('./tracking');

var _tracking2 = _interopRequireDefault(_tracking);

var _iframe = require('./iframe');

var _iframe2 = _interopRequireDefault(_iframe);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Widget = function () {
  _createClass(Widget, null, [{
    key: 'generateId',
    value: function generateId() {
      var usedIds = this.usedIds;
      var currId = void 0;
      do {
        currId = Math.random();
      } while (usedIds.indexOf(currId) !== -1);
      return this.usedIds.push(currId);
    }
  }]);

  function Widget(_ref) {
    var _this = this;

    var container = _ref.container,
        dataset = _ref.dataset,
        session = _ref.session,
        anonymousId = _ref.anonymousId,
        testId = _ref.testId,
        sessionExpiry = _ref.sessionExpiry;

    _classCallCheck(this, Widget);

    // Check for required args
    var requiredArgs = {
      container: container,
      dataset: dataset,
      templateId: dataset.templateId,
      businessUnitId: dataset.businessunitId,
      locale: dataset.locale
    };
    Object.keys(requiredArgs).forEach(function (arg) {
      if (!requiredArgs[arg]) {
        throw 'No ' + arg + ' supplied for TrustBox';
      }
    });

    this.container = container;
    this.container.style.position = 'relative';

    this.dataset = dataset;
    this.iframes = {};
    this.tracking = new _tracking2.default(function (payload) {
      return _this.iframes.main.sendMessage(payload);
    }, function () {
      return _this.isInViewport(0.5);
    }, {
      session: session,
      group: dataset.group,
      sessionExpiry: sessionExpiry,
      anonymousId: anonymousId,
      testId: testId,
      templateId: dataset.templateId
    });
    this.stats = { createIFrameCalls: 0, iframeLoadEvents: 0, events: {} };
  }

  _createClass(Widget, [{
    key: 'initialize',
    value: function initialize() {
      var widgetIFrameProtocol = 'https:' === document.location.protocol ? 'https:' : 'http:';
      this._widgetIFrameOrigin = widgetIFrameProtocol + '#{WidgetRootNoProtocol}';
      this.createIFrame('main');
      this.attachMessageListener();
    }
  }, {
    key: 'handleCommand',
    value: function handleCommand(_ref2) {
      var data = _ref2.data,
          origin = _ref2.origin;

      try {
        var parsedData = typeof data === 'string' ? JSON.parse(data) : data;
        var brandCommands = ['activate-old-brand', 'activate-new-brand'];
        var isBrandingEvent = brandCommands.indexOf(parsedData.command) !== -1;
        // Check if event is valid: it is either a branding event, or the event
        // has a correct origin and widgetId.
        var isValidEvent = isBrandingEvent || origin === this._widgetIFrameOrigin && parsedData.widgetId === this.id;
        if (!isValidEvent) {
          return false;
        }
        return this.widgetIframeMessageHandler(parsedData);
      } catch (ex) {
        // eslint-disable-next-line
        return false;
      }
    }
  }, {
    key: 'attachMessageListener',
    value: function attachMessageListener() {
      window.addEventListener('message', this.handleCommand.bind(this), false);
    }

    /**
     * Handle messages intended for widgets/iframes.
     *
     * @param {Object} data - The message received.
     * @param {Function} fallback - An optional function called where no matching
     * action is found for the message received.
     */

  }, {
    key: 'widgetIframeMessageHandler',
    value: function widgetIframeMessageHandler(data) {
      var _this2 = this;

      var fallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

      this.stats.events[data.command] = (this.stats.events[data.command] || 0) + 1;
      var name = data.name,
          style = data.style,
          height = data.height;

      var iframe = this.iframes[name];

      var actions = {
        createIFrame: function createIFrame() {
          return _this2.createIFrame(name, data);
        },
        setStyle: function setStyle() {
          return iframe.setStyle(style);
        },
        loaded: function loaded() {
          return _this2.iframes.main.sendMessage('loaded');
        },
        message: function message() {
          return iframe.sendMessage(data);
        },
        'resize-height': function resizeHeight() {
          return _this2.getIframeOrMain(name).resizeHeight(height);
        },
        impression: function impression() {
          return _this2.tracking.initialize();
        },
        'activate-old-brand': function activateOldBrand() {
          return _this2.switchBrand('old');
        },
        'activate-new-brand': function activateNewBrand() {
          return _this2.switchBrand('new');
        }
      };

      var action = actions[data.command];
      if (action) {
        action();
        return true;
      }

      fallback();
      return false;
    }
  }, {
    key: 'switchBrand',
    value: function switchBrand(brand) {
      var _this3 = this;

      var command = brand === 'new' ? 'activate-new-brand' : 'activate-old-brand';
      Object.keys(this.iframes).forEach(function (key) {
        _this3.iframes[key].sendMessage({ command: command });
      });
    }
  }, {
    key: 'createIFrame',
    value: function createIFrame(name, iframeData) {
      var _this4 = this;

      var iframe = new _iframe2.default(name, this.dataset, iframeData);
      this.iframes[name] = iframe;

      this.stats.createIFrameCalls += 1;
      iframe.initialize(this.container, function () {
        iframe.setWidgetId(_this4.id);
        _this4.stats.iframeLoadEvents += 1;
      });
    }
  }, {
    key: 'getIframeOrMain',
    value: function getIframeOrMain(iframeName) {
      return this.iframes[iframeName] || this.iframes.main;
    }
  }, {
    key: 'isInViewport',
    value: function isInViewport(allowance) {
      return this.iframes.main.isInViewport(allowance);
    }
  }, {
    key: 'closePopup',
    value: function closePopup() {
      // Only execute if we have a popup iframe
      if ('popup' in this.iframes) {
        // Message payload used to signal to the main iframe that a popup
        // has been closed. This mirrors the message defined in the vanilla
        // framework at
        // https://github.com/trustpilot/node-trustbox-framework-vanilla/blob/09d18b9d3a8032bca52c87a9ad0364e464db2c3d/modules/communication.js#L85function.
        var mainPayload = {
          name: 'main',
          command: 'message',
          message: 'popup toggled',
          visible: false
        };
        this.iframes.main.sendMessage(mainPayload);
        this.iframes.popup.hide();
      }
    }
  }, {
    key: 'id',
    get: function get() {
      if (!this._id) {
        this._id = this.constructor.generateId();
      }
      return this._id;
    }
  }]);

  return Widget;
}();

Widget.usedIds = [];
exports.default = Widget;

},{"./iframe":5,"./tracking":7}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _constants = require('../constants');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Tracking = function () {
  function Tracking(sendMessage, isTrustBoxVisible, _ref) {
    var session = _ref.session,
        sessionExpiry = _ref.sessionExpiry,
        group = _ref.group,
        anonymousId = _ref.anonymousId,
        testId = _ref.testId,
        templateId = _ref.templateId;

    _classCallCheck(this, Tracking);

    this.hasSentImpression = false;
    this.hasSentViewTracking = false;
    this.sendMessage = sendMessage;
    this.isTrustBoxVisible = isTrustBoxVisible;

    this.session = session;
    this.group = group;
    this.sessionExpiry = sessionExpiry;
    this.anonymousId = anonymousId;
    this.testId = testId;
    this.templateId = templateId;
  }

  /**
   * Initialize a Tracking instance.
   *
   * This should only be called once the TrustBox has indicated it is ready to
   * handle tracking events.
   */


  _createClass(Tracking, [{
    key: 'initialize',
    value: function initialize() {
      this.sendImpressionData();
      this.attachListener();
      this.shouldDetachListener();
    }
  }, {
    key: 'getTrackingData',
    value: function getTrackingData(commandName) {
      var data = {
        command: commandName,
        url: window.document.URL,
        referrer: window.document.referrer,
        userAgent: window.navigator.userAgent,
        language: window.navigator.userLanguage || window.navigator.language,
        platform: window.navigator.platform
      };

      if (this.session && this.group) {
        data.session = this.session;
        data.sessionExpiry = this.sessionExpiry;
      }

      if (this.anonymousId) {
        data.anonymousId = this.anonymousId;
      }

      if (this.testId) {
        data.testId = this.testId;
      }

      return data;
    }
  }, {
    key: 'sendImpressionData',
    value: function sendImpressionData() {
      if (this.hasSentImpression) {
        return;
      }
      this.hasSentImpression = true;

      var data = this.getTrackingData('impression-received');
      this.sendMessage(data);
    }
  }, {
    key: 'sendVisibilityData',
    value: function sendVisibilityData() {
      if (this.hasSentViewTracking) {
        return;
      }
      this.hasSentViewTracking = true;

      var data = this.getTrackingData('trustbox-in-viewport');
      this.sendMessage(data);
    }
  }, {
    key: 'attachListener',
    value: function attachListener() {
      var _arguments = arguments,
          _this = this;

      var debounce = function debounce(func, wait) {
        var timeout = void 0;
        return function () {
          var args = _arguments;
          var later = function later() {
            timeout = null;
            func(args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      };

      var checkViewPortVisibility = debounce(function () {
        var isEmptyWidget = _this.templateId === _constants.EMPTY_WIDGET_ID; // Filter out empty iframe widgets from the view tracking
        var removeListener = isEmptyWidget || _this.shouldDetachListener();
        if (removeListener) {
          /* eslint-disable */
          window.removeEventListener('scroll', scrollEventListener, eventOptions);
          window.removeEventListener('resize', resizeEventListener, eventOptions);
          /* eslint-enable */
        }
      }, 100);

      var eventOptions = { passive: true, capture: false };
      var scrollEventListener = window.addEventListener('scroll', checkViewPortVisibility, eventOptions);
      var resizeEventListener = window.addEventListener('resize', checkViewPortVisibility, eventOptions);
      checkViewPortVisibility();
    }

    /**
     * Track the visibility of the TrustBox.
     *
     * What this method actually does is set the current 'seen' state of the
     * TrustBox, and returns a boolean flag which can be used to determine
     * whether or not to continue listening for visibility events and calling
     * this method.
     */

  }, {
    key: 'shouldDetachListener',
    value: function shouldDetachListener() {
      // we can't send the view data until the iframe is ready to receive it.
      // We're using `hasSentImpression` to know it's loaded and rely on the
      // `impression` message handler to call us when that changes.
      if (this.hasSentViewTracking) {
        return true;
      }

      // already detected it, weren't ready to send earlier though
      if (this.hasBeenVisible) {
        if (this.hasSentImpression) {
          this.sendVisibilityData();
        }
        return true;
      }

      if (!this.isTrustBoxVisible()) {
        return false;
      }

      if (!this.hasSentImpression) {
        this.hasBeenVisible = true;
        return true;
      }

      this.sendVisibilityData();
      return true;
    }
  }]);

  return Tracking;
}();

exports.default = Tracking;

},{"../constants":2}],8:[function(require,module,exports){
'use strict';

function parse(req) {
  try {
    return JSON.parse(req.responseText);
  } catch (e) {
    return req.responseText;
  }
}

function xhrGet(params) {
  var request = new window.XMLHttpRequest();
  request.open('GET', params.url, true);
  request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      if (request.status >= 200 && request.status < 300) {
        params.success(parse(request));
      } else if (params.error) {
        params.error(parse(request));
      }
    }
  };
  request.send();
}

module.exports = { xhrGet: xhrGet };

},{}],9:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/* eslint global-require: 0 */
(function (currentVersion) {
  try {
    (function () {
      var WidgetManagement = require('./lib/widget-management.js').default;

      window.Trustpilot = window.Trustpilot || {
        loadFromElement: function loadFromElement(element, forceReload) {
          return this.Modules.WidgetManagement && this.Modules.WidgetManagement.applyWidgetFromDomElement(element, forceReload);
        }
      };
      window.Trustpilot.Modules = window.Trustpilot.Modules || {};

      if (!window.Trustpilot.Modules.WidgetManagement) {
        window.Trustpilot.Modules.WidgetManagement = new WidgetManagement(window, document, currentVersion);
        window.Trustpilot.Modules.WidgetManagement.initializeOnPageLoad();
      } else if (window.Trustpilot.Modules.WidgetManagement.version !== currentVersion) {
        // eslint-disable-next-line
        console.log('Detected legacy TrustBox bootstrap with version:', window.Trustpilot.Modules.WidgetManagement.version, ', current:', currentVersion);
      }
      window.addEventListener('click', function () {
        window.Trustpilot.Modules.WidgetManagement.closePopups();
      });
    })();
  } catch (e) {
    (function () {
      function postError(message) {
        // eslint-disable-next-line
        console.error('Error on bootstrap:' + message);

        var url = '#{WidgetRootNoProtocol}/feedback/report-error';
        var params = ['error=' + encodeURIComponent(message), 'uri=' + encodeURIComponent(document.URL), 'bootstrapVersion=' + currentVersion].join('&');

        var img = document.createElement('img');
        img.src = url + '?' + params;
      }

      try {
        if ((typeof e === 'undefined' ? 'undefined' : _typeof(e)) === 'object') {
          postError(e.message);
        } else {
          postError(e);
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error('Error on error reporting method:' + e);
      }
    })();
  }
})('#{Octopus.Release.Number}');

},{"./lib/widget-management.js":4}]},{},[9]);
